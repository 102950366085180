import { authorizeWithAny, cleanLocalStorage } from '@/services/auth/auth'
import { STEP_LOGIN, StepType } from '@/services/auth/constants'
import { deleteToken } from '@/services/cookies/cookiesServerActions'
import { getLandingLoggedInUrl } from '@/util/migrationSPA'
import { getUrl } from '@/util/next'
import { usePathname, useSearchParams } from 'next/navigation'
import { showToast } from '../core/Toast'

/**
 * Pauses execution for a given number of milliseconds.
 *
 * @param ms - The number of milliseconds to delay.
 * @returns A promise that resolves after the specified delay.
 */
const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Hook to manage redirection to the authentication URL and handle unauthorized access.
 *
 * This hook provides functions to redirect the user to the appropriate authentication URL
 * based on the current pathname and search parameters.
 *
 * @returns An object containing the `redirect` and `unauthorized` functions.
 */
export const useRedirectToAuthUrl = () => {
  const REDIRECT_DELAY = 1000 // 1 second

  const path = usePathname()
  const params = useSearchParams()

  const url = getLandingLoggedInUrl(getUrl(path))

  /**
   * Redirects the user to the authentication URL with an optional step parameter.
   *
   * @param [step] - Optional step type for the redirection process.
   */
  const redirect = async (step?: StepType) => authorizeWithAny(params, url, step)

  /**
   * Handles unauthorized access by logging out the user, showing a toast notification,
   * and redirecting to the login step after a delay.
   */
  const unauthorized = async () => {
    try {
      showToast({
        variant: 'error',
        content: 'There was a problem with your session, you will be redirected to the login page.',
        dismissible: false,
      })

      // Waits for a specified time before proceeding with logout and redirection
      await delay(REDIRECT_DELAY)

      await deleteToken()
      cleanLocalStorage()
      await redirect(STEP_LOGIN)
    } catch (e) {
      throw e
    }
  }

  return { redirect, unauthorized }
}
