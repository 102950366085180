import { IconComponentType } from '../types'

const SuccessIcon = ({ size, className = '' }: IconComponentType) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>success icon</title>
    <path
      d="M9.55005 17.6539L4.21545 12.3193L5.28468 11.2501L9.55005 15.5155L18.7154 6.3501L19.7847 7.4193L9.55005 17.6539Z"
      fill="currentColor"
    />
  </svg>
)

export default SuccessIcon
