import { GravityTypographyType } from '@/components/gravityTypes'
import clsx from 'clsx'
import React from 'react'
import styles from './Typography.module.scss'

export type TypographyProps = (
  | {
      as?: React.ElementType
      variant?: 'cta'
      size?: 'regular' | 'small' | 'xsmall'
    }
  | GravityTypographyType
) &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>

const Typography = ({
  as: Component = 'span',
  variant = 'plain',
  size = 'regular',
  className,
  ...props
}: TypographyProps) => (
  <Component {...props} className={clsx(className, styles[variant], styles[size])} />
)

export default Typography
