'use client'

import { useRedirectToAuthUrl } from '@/components/hooks/useRedirectToAuthUrl'
import { useEventContext } from '@/components/providers/eventContext'
import { STEP_SIGNUP } from '@/services/auth/constants'
import { Suspense } from 'react'
import Button, { ButtonProps } from '../Button'

import styles from './SignupButton.module.scss'

const SignupButtonNoSuspense = (props: ButtonProps) => {
  const { getEventProps } = useEventContext({
    name: 'signup_click',
  })
  const { redirect } = useRedirectToAuthUrl()
  return (
    <Button
      className={styles.button}
      {...props}
      onClick={() => redirect(STEP_SIGNUP)}
      {...getEventProps()}
    />
  )
}

const SignupButton = (props: ButtonProps) => (
  <Suspense fallback={<Button {...props} />}>
    <SignupButtonNoSuspense {...props} />
  </Suspense>
)

export default SignupButton
